<template>
  <div class="is-background-footer">
    <div class="section has-text-centered">
      <div class="container">
        <Form />
      </div>
      <div class="columns is-mobile is-vcentered is-social">
        <div class="column">
          <figure class="image is-inline-block">
            <img v-if="isMobile" src="@/assets/img/chelita-logo-vertical.png" />
            <img v-else src="@/assets/img/chelita-logo.png" />
          </figure>
        </div>
        <div class="column is-flex-desktop is-flex-wrap has-text-left-mobile">
          <p>
            <span class="is-hidden-mobile">Síguenos</span>
            <a href="https://www.facebook.com/chelita.soft">
              <span class="is-rounded ml-2 mr-2"
                ><i class="fab fa-facebook has-text-light"></i
              ></span>
            </a>
            <span class="is-rounded mr-2"
              ><i class="fab fa-twitter has-text-light"></i
            ></span>
            <a href="https://instagram.com/_u/chelita.soft/">
              <span class="is-rounded mr-2"
                ><i class="fab fa-instagram has-text-light"></i
              ></span>
            </a>
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=+525529405706&text=Hola, necesito más información sobre Chelita Software!"
          >
            <p class="has-borders-desktop">
              <i class="fab fa-whatsapp has-text-blue mr-1"></i>
              +52 55 2940 5706
            </p>
          </a>
          <a href="mailto:contacto@chelita.com.mx">
            <p>
              <i class="fas fa-envelope has-text-blue mr-1"></i>
              contacto@chelita.com.mx
            </p>
          </a>
        </div>
      </div>
      <div class="is-divider is-inline-block"></div>
      <div class="columns is-vcentered is-tools-section">
        <div
          class="column has-text-left-desktop is-2-desktop is-offset-2-desktop"
        >
          <p class="is-title">Nuestras Herramientas</p>
          <p class="is-text mt-2">
            Nos valemos de herramientas probadas y utilizadas en la industria
            para construir las mejores soluciones.
          </p>
        </div>
        <div class="column is-7-desktop">
          <slider />
        </div>
      </div>
      <div class="is-divider is-blue is-inline-block"></div>
      <div class="columns pt-5">
        <div class="column">
          <p class="has-text-dark-blue">
            Chelita Software 2023 ® Todos los derechos reservados
          </p>
        </div>
        <div class="column">
          <div class="is-flex is-justify-content-center">
            <p class="has-text-dark-blue has-border-right">
              Términos y Condiciones
            </p>
            <p class="has-text-dark-blue">Politica de privacidad</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form.vue";
import Slider from "@/components/Utils/Slider.vue";

export default {
  props: ["is-mobile"],
  name: "Footer",
  components: { Form, Slider }
};
</script>

<style scoped>
.is-background-footer {
  margin-top: 200px;
  background-image: url("@/assets/img/background-footer.png");
  background-size: cover;
  height: 900px;
}

.section {
  position: relative;
  top: -100px;
}

a {
  color: #edeeed;
}

.is-social {
  position: relative;
  z-index: 1;
}

.is-rounded {
  padding: 5px 6px;
  border-radius: 50px;
  background: #166b93;
}

.column.is-flex-desktop p {
  font-size: 12px !important;
  padding: 0px 20px;
}

.has-borders-desktop {
  border-left-width: 1px;
  border-top-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #166b93;
}

.is-divider {
  width: 80%;
  opacity: 0.2;
  border: 1px solid var(--Light-Gray, #edeeed);
}

.is-divider.is-blue {
  opacity: 1;
  border-color: var(--Dark-Blue, #166b93) !important;
}

.is-title {
  font-size: 18px;
  font-weight: 600;
  color: #2489ca;
}

.is-text {
  font-size: 12px;
  font-weight: 600;
}

.is-tools-section {
  padding: 50px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .container {
    height: 580px !important;
  }

  .is-background-footer {
    margin-top: 450px;
    background-image: url("@/assets/img/background-footer-mobile.png");
    height: 1000px;
  }

  .section {
    position: relative;
    top: -250px;
  }

  .column.is-flex-desktop p {
    font-size: 10px !important;
    padding: 10px 0px;
  }

  .has-borders-desktop {
    border: none;
  }

  .is-divider {
    width: 100%;
  }

  span {
    font-size: 20px !important;
  }
}

.has-text-dark-blue {
  color: var(--Blue, #2489ca);
  font-size: 10px;
  font-weight: 600;
  padding: 0px 20px;
}

.has-border-right {
  border-right-style: solid;
  border-color: #166b93;
  border-right-width: 1px;
}
</style>