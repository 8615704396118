<template>
  <div class="is-main section">
    <div class="columns is-vcentered">
      <div class="column is-offset-2-desktop is-4 has-text-centered-mobile">
        <p class="has-text-light-blue mb-3">DESARROLLO DE SOFTWARE</p>
        <p class="is-main-title">
          Aplicaciones e infraestructura <br />
          <span class="has-text-blue">en la nube</span>
        </p>
        <p class="mt-3">Adaptarse a la era digital puede ser complicado</p>
        <a class="button is-blue mt-5" @click="openModal()">Permítenos Guiarte →</a>
      </div>
      <div class="column has-text-centered-mobile">
        <figure class="image is-inline-block">
          <img src="@/assets/img/main-image.png" />
        </figure>
      </div>
    </div>
    <FormModal :is-active="isModalActive" :is-mobile="isMobile" @close-modal="closeModal"/>
  </div>
</template>

<script>
import FormModal from "@/components/Utils/FormModal.vue"

export default {
  props: ["is-mobile"],
  components: {FormModal},
  name: "MainBanner",
  data() {
    return {
      isModalActive: false
    }
  },
  methods: {
    openModal() {
      this.isModalActive = true
    },
    closeModal() {
      this.isModalActive = false
    }
  }
};
</script>

<style scoped>
.is-main {
  background: linear-gradient(172deg, #2c2c31 40%, #166b93 100%);
  height: 724px;
}

.colums {
  height: 600px;
}

.image {
  padding-top: 40px;
  width: 80%;
}

.has-text-light-blue {
  font-size: 18px;
  color: var(--light-blue, #15d9fd);
  letter-spacing: 5.4px;
  font-weight: 600;
}

.is-main-title {
  font-size: 60px;
  font-weight: 800;
  line-height: 100%; /* 60px */
}

@media only screen and (max-width: 768px) {
  .image {
    padding-top: 20px;
    width: 100%;
  }

  .has-text-light-blue {
    font-size: 12px;
    color: var(--light-blue, #15d9fd);
    letter-spacing: 3.5px;
    font-weight: 600;
  }

  .is-main-title {
    font-size: 26px;
  }
}

.button.is-blue {
  font-size: 14px;
  padding: 20px 25px !important;
  border-style: none;
  border-radius: 50px;
  background: var(--dark-blue, #166b93);
  color: var(--light-gray, #edeeed) !important;
}
</style>