<template>
	<div>
		<navigation />
		<title-box :subtitle="subtitle" :title="title" :text="text" />
		<projects-main />
		<div class="is-background-chelita">
			<Footer :is-mobile="isMobile" id="form" />
		</div>
	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import TitleBox from '@/components/Utils/TitleBox.vue';
import ProjectsMain from '@/components/Projects/ProjectsMain.vue';

export default {
	name: 'Projects',
	components: { Navigation, Footer, TitleBox, ProjectsMain },
	data() {
		return {
			subtitle: 'Lorem ipsum dolor',
			title: 'Nuestros Proyectos',
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
		};
	},
	computed: {
		isMobile() {
			return this.windowWidth <= 769;
		},
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
};
</script>

<style></style>
