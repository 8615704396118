<template>
	<div class="box is-inline-block" :class="{'is-full-height-mobile': isModal}">
		<div class="columns">
			<div v-show="!(isModal && isMobile)" class="column is-offset">
				<figure class="image is-inline-block">
					<img src="@/assets/img/form-image.png" />
				</figure>
			</div>
			<div class="column has-text-left-desktop" :class="{'is-offset-mobile': !isModal}">
				<p class="is-title">Tomemos una <span class="has-text-light-blue">Chelita</span></p>
				<p class="is-text has-text-light">Y hablemos de transformación digital.</p>
				<div class="field is-horizontal mt-4">
					<div class="field-body">
						<div class="field">
							<input class="input" type="text" v-model="name" id="name" name="name" placeholder="Nombre" />
						</div>
						<div class="field">
							<input class="input is-success" type="tel" v-model="phone" id="phone" name="phone" placeholder="Teléfono" />
						</div>
						<div class="field">
							<input class="input is-success" type="email" v-model="email" id="email" name="email" placeholder="Correo" />
						</div>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<textarea class="textarea" v-model="text" id="text" name="text" placeholder="Mensaje"></textarea>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<button class="button is-fullwidth" :class="{ 'is-loading': loading }" @click="sendEmail()">
							Enviar
							<svg
								class="ml-2"
								:class="{ 'is-hidden': loading }"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.5075 15.75L17.25 9L1.5075 2.25L1.5 7.5L12.75 9L1.5 10.5L1.5075 15.75Z" fill="#166B93" />
							</svg>
						</button>
            <div v-if="message" class="box is-flex" :class="success ? 'has-background-success' : 'has-background-danger'" id="pop-message">
              <p class="has-text-light">{{message}}</p>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ["is-modal", "is-mobile"],
	name: 'Form',
	data() {
		return {
			name: '',
			phone: '',
			email: '',
			text: '',
			loading: false,
			message: '',
      success: false
		};
	},
	methods: {
		async sendEmail() {
			this.loading = true;
			this.message = '';
      this.success = false;
			if (this.name === '' && this.phone === '' && this.email === '' && this.text === '') {
				this.message = 'Debes rellenar todos los campos :(';
			}
      if (!this.message) {
        const payload = { name: this.name, phone: this.phone, email: this.email, text: this.text };
        try {
          const response = await this.$axios.post('https://chelita.com.mx/api/email.php', payload);
          console.log(response.data)
          if (response.data?.success) { 
            this.message = "Mensaje enviado con éxito :)", 
            this.success = true
            this.clearData();
          }
          else { this.message = `${response.data.message} :(` }
        } catch (err) {
          this.message = 'Error al enviar mensaje :(';
        }
      }
			this.loading = false;
      setTimeout(() => {
        this.message = '';
      }, "2000");
		},
    clearData() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.text = "";
    }
	},
};
</script>

<style scoped>
.box {
	width: 1000px;
	height: 391px;
	border-radius: 20px;
	background: linear-gradient(90deg, rgba(22, 107, 147, 0.95) 2.29%, #2489ca 85.2%);
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.is-offset {
	position: relative;
	top: -100px !important;
}

.image {
	width: 65%;
}

.is-title {
	font-size: 40px;
	font-weight: 800;
	color: var(--Light-Gray, #edeeed);
}

.is-text {
	font-size: 16px;
	font-weight: 600;
}

.input {
	color: #edeeef;
	border-style: none;
	border-radius: 8px;
	background: var(--Dark-Blue, #166b93);
}

.input::placeholder {
	opacity: 0.7;
	color: #edeeed;
	font-size: 16px;
}

.textarea {
	color: #edeeef;
	border-style: none;
	border-radius: 8px;
	background: var(--Dark-Blue, #166b93);
}

.textarea::placeholder {
	opacity: 0.7;
	color: #edeeed;
	font-size: 16px;
}

#pop-message {
  background: hsl(347, 90%, 96%);
  width: 100%;
	height: 60px;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
	.box {
		height: 511px;
		width: 95%;
	}

	.is-title {
		font-size: 22px;
	}

	.is-text {
		font-size: 12px;
	}

	.input {
		font-size: 12px;
	}

	.input::placeholder {
		font-size: 12px;
	}

	.textarea {
		font-size: 12px;
	}

	.textarea::placeholder {
		font-size: 12px;
	}

	.is-offset {
		top: -230px !important;
	}

	.is-offset-mobile {
		position: relative;
		top: -200px !important;
	}

  #pop-message {
    height: 80px;
  }

  .is-full-height-mobile {
    height: 100%;
  }
}

.button {
	color: #166b93;
	border-radius: 50px;
	background: var(--Light-Gray, #edeeed);
}
</style>
