<template>
    <div class="modal" :class="{'is-active': isActive}">
        <div class="modal-background"></div>
        <div class="modal-content">
            <Form :is-modal="true" :is-mobile="isMobile"/>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="handleClose()"></button>
    </div>
</template>
<script>
import Form from "@/components/Form.vue";

export default {
    props: ["is-active", "is-mobile"],
    components: {Form},
    methods: {
        handleClose() {
            this.$emit('close-modal');
        }
    }
}
</script>

<style scoped>
.modal-content {
    width: fit-content;
    height: fit-content;
    overflow: visible;
}
</style>