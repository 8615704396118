import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
import AboutUs from './pages/AboutUs.vue';
import Services from './pages/Services.vue';
import Projects from './pages/Projects.vue';
import axios from 'axios';

const PageNotFound = { template: '<h1>Not found</h1>' };

const routes = [
	{ path: '/', name: 'home', component: Home },
	{ path: '/nosotros', name: 'nosotros', component: AboutUs },
	{ path: '/servicios', name: 'servicios', component: Services },
    { path: '/proyectos', name: 'proyectos', component: Projects },
	{ path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
	// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	routes: routes, // short for `routes: routes`
});

const app = createApp({});
app.config.globalProperties.$axios = axios;
app.use(router);
app.mount('#app');

require('@/assets/css/main.css');
