<template>
  <div class="is-main-cards is-flex is-flex-wrap-wrap is-justify-content-center">
    <div class="box">
        <div class="columns is-mobile is-vcentered">
            <div class="column has-text-centered">
                <figure class="image is-inline-block">
                    <img src="@/assets/img/mobile.png" />
                </figure>
            </div>
            <div class="column has-text-light">
                <p class="card-title">Aplicaciones</p>
                <p class="card-text mt-2">Crea nuevas formas de experimentar tu negocio desde un celular</p>
                <p class="card-go-to mt-3">Demo →</p>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns is-mobile is-vcentered">
            <div class="column has-text-centered">
                <figure class="image is-inline-block">
                    <img src="@/assets/img/commerce.png" />
                </figure>
            </div>
            <div class="column has-text-light">
                <p class="card-title">eCommerce</p>
                <p class="card-text mt-2">Implementa y automatiza tu negocio en línea</p>
                <p class="card-go-to mt-3">Demo →</p>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="columns is-mobile is-vcentered">
            <div class="column has-text-centered">
                <figure class="image is-inline-block">
                    <img src="@/assets/img/enterprise.png" />
                </figure>
            </div>
            <div class="column has-text-light">
                <p class="card-title">ERP y BI</p>
                <p class="card-text mt-2">Controla tu negocio desde una sola herramienta</p>
                <p class="card-go-to mt-3">Demo →</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "MainCards"
};
</script>

<style scoped>
.is-main-cards {
    position: relative;
    top: -95px;
}

.box {
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    width: 308px;
    height: 170px;
    background: var(--gradient-left, linear-gradient(90deg, #1B4B61 0%, #2C2C31 99.79%));
}

.card-title {
    font-size: 16px;
    font-weight: 700;
}

.card-text {
    font-size: 10px;
    line-height: 180%; /* 18px */
}

.card-go-to {
    font-size: 10px;
    color: var(--light-blue, #15D9FD);
}
</style>