<template>
	<nav class="navbar is-dark" role="navigation" aria-label="main navigation">
		<div class="navbar-item is-top is-hidden-touch">
			<div class="navbar-item is-justify-content-flex-end has-border-right has-text-light">
				<a
					href="https://api.whatsapp.com/send?phone=+525529405706&text=Hola, necesito más información sobre Chelita Software!"
					class="is-flex is-align-items-center"
					><i class="fab fa-whatsapp has-text-dark mr-2"></i> +52 55 2940 5706</a
				>
			</div>
			<div class="navbar-item has-text-light">
				<a href="mailto:contacto@chelita.com.mx" class="is-flex is-align-items-center"
					><i class="fas fa-envelope has-text-dark mr-2"></i>
					contacto@chelita.com.mx
				</a>
			</div>
			<div class="navbar-item is-justify-content-flex-end has-border-right has-text-light">
				<a href="https://www.facebook.com/chelita.soft"><i class="fab fa-facebook has-text-dark mr-2"></i></a>
				<i class="fab fa-twitter has-text-dark mr-2"></i>
				<a href="https://instagram.com/_u/chelita.soft/"><i class="fab fa-instagram has-text-dark mr-2"></i></a>
				Síguenos
			</div>
			<div class="navbar-item has-text-light">
				<img class="mr-2" src="@/assets/img/language-en.png" />
				English
			</div>
		</div>
		<div class="navbar-brand is-main">
			<a class="navbar-item is-logo" href="/">
				<img src="@/assets/img/chelita-logo.png" />
			</a>

			<a
				@click="openMenu()"
				role="button"
				class="navbar-burger"
				:class="{ 'is-active': isMenuActive }"
				aria-label="menu"
				aria-expanded="false"
				data-target="navbarBasicExample"
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		<div class="navbar-menu" :class="{ 'is-active': isMenuActive }" id="navMenu">
			<div class="navbar-start has-text-centered-mobile">
				<a class="navbar-item" href="/">Inicio</a>
				<a class="navbar-item" href="/nosotros">Nosotros</a>
				<a class="navbar-item" href="/servicios">Servicios</a>
				<!--<a class="navbar-item" href="/proyectos">Proyectos</a>-->
				<a class="navbar-item is-hidden-desktop" href="#form">Hablemos</a>
				<div class="is-hidden-desktop is-dark-blue is-felx-direction-column">
					<a
						href="https://api.whatsapp.com/send?phone=+525529405706&text=Hola, necesito más información sobre Chelita Software!"
						class="navbar-item is-flex is-justify-content-center is-align-items-center"
						><i class="fab fa-whatsapp has-text-dark mr-2"></i> +52 123 456</a
					>
					<a href="mailto:contacto@chelita.com.mx" class="navbar-item is-flex is-justify-content-center is-align-items-center"
						><i class="fas fa-envelope has-text-dark mr-2"></i> contacto@chelita.com.mx</a
					>
					<a class="navbar-item is-flex is-justify-content-center">
						<img class="mr-2" src="@/assets/img/language-en.png" />
						English
					</a>
					<div class="navbar-item is-flex is-justify-content-center">
						<a href="https://www.facebook.com/chelita.soft"><i class="fab fa-facebook has-text-dark mr-2"></i></a>
						<a><i class="fab fa-twitter has-text-dark mr-2"></i></a>
						<a href="https://instagram.com/_u/chelita.soft/"><i class="fab fa-instagram has-text-dark mr-2"></i></a>
					</div>
				</div>
			</div>
			<div class="navbar-end is-hidden-touch">
				<div class="navbar-item">
					<a class="button is-blue" href="#form">Hablemos</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'Navigation',
	data() {
		return {
			isMenuActive: false,
		};
	},
	methods: {
		openMenu() {
			this.isMenuActive = !this.isMenuActive;
		},
	},
};
</script>

<style scoped>
.navbar {
	flex-wrap: wrap;
}

.navbar-brand {
	align-items: center;
}

.navbar-item.is-top {
	height: 34px;
	background: var(--dark-blue, #166b93);
	width: 100%;
	font-size: 12px;
}

.navbar-item a {
	font-size: 12px;
}

.navbar-item.is-logo img {
	max-height: none;
	height: 35px;
}

.navbar-item svg {
	font-size: 18px;
}

@media only screen and (min-width: 768px) {
	.navbar-brand.is-main {
		width: 33%;
		justify-content: center;
	}
}

@media only screen and (max-width: 768px) {
	.navbar-menu {
		background-color: var(--Dark-Gray, #2c2c31) !important;
	}

	.navbar-start {
		width: 100% !important;
	}

	.navbar-item {
		padding: 20px 0px;
	}

	.navbar-item.is-hidden-desktop {
		background: var(--Blue, #2489ca);
	}

	.is-dark-blue {
		background: #166b93 !important;
	}

	.navbar-item svg {
		font-size: 25px;
	}

	.is-hidden-desktop .navbar-item a {
		padding: 0px 20px;
	}
}

.navbar-start {
	justify-content: center;
	width: 50%;
}

.navbar-end {
	justify-content: center;
	width: 50%;
}

a {
	color: #edeeed;
	font-family: Open Sans;
	font-size: 14px;
}

.is-logo {
	height: 65px;
}

.button.is-blue {
	font-size: 14px;
	padding: 20px 25px !important;
	border-style: none;
	border-radius: 50px;
	background: var(--dark-blue, #166b93);
	color: var(--light-gray, #edeeed) !important;
}

.is-top {
	overflow: hidden;
}

.is-top .navbar-item {
	width: 25%;
}

.has-border-right {
	border-right: solid 1px #2489ca;
}
</style>
