<template>
	<div>
		<navigation />
        <title-box :subtitle="subtitle" :title="title" :text="text"/>
        <about-us-description />
		<div class="is-background-chelita">
            <about-us-team v-show="false"/>
			<Footer :is-mobile="isMobile" id="form" />
		</div>
	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import TitleBox from '@/components/Utils/TitleBox.vue';
import AboutUsDescription from '@/components/AboutUs/AboutUsDescription.vue'
import AboutUsTeam from '@/components/AboutUs/AboutUsTeam.vue';

export default {
	name: 'AboutUs',
	components: { Navigation, Footer, TitleBox, AboutUsDescription, AboutUsTeam },
	data() {
		return {
			windowWidth: null,
            subtitle: "Permitenos guíarte",
            title: "Sobre Nosotros",
            text: "Más allá del desarrollo de software"
		};
	},
	computed: {
		isMobile() {
			return this.windowWidth <= 769;
		},
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
};
</script>

<style></style>
