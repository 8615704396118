<template>
	<div class="section">
		<div class="has-text-centered is-title-section">
			<p class="is-subtitle">RAZONES PARA ELEGIRNOS</p>
			<p class="is-title mt-3">Ofrecemos soluciones eficaces para tu adaptación digital</p>
		</div>
		<div class="container is-flex is-flex-wrap-wrap is-justify-content-center">
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-1.png" />
				</figure>
				<p class="is-box-title mt-2">Desarrollo web</p>
				<p class="is-box-text mt-3">Maquetado básico, desarrollo de plantillas, tableros y todo tipo de sitios web usando html, css y javascript.</p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-2.png" />
				</figure>
				<p class="is-box-title mt-2">Automatización</p>
				<p class="is-box-text mt-3">Construimos pipelines para automatizar despliegue de servidores, monitoreos, scrapping de sitios web o migraciones de datos.</p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-3.png" />
				</figure>
				<p class="is-box-title mt-2">Single Page Apps</p>
				<p class="is-box-text mt-3">Desarrollamos aplicaciones con funcionalidades complejas como mapas, pagos, carritos, etc. Utilizando frameworks de última generación como React, Angular o Vue JS </p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-4.png" />
				</figure>
				<p class="is-box-title mt-2">Diseño UX/UI</p>
				<p class="is-box-text mt-3">Diseñamos interfaces y prototipos de aplicaciones basadas en la experiencia de usuario</p>
			</div>
		</div>
		<div class="container is-flex is-flex-wrap-wrap is-justify-content-center">
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-5.png" />
				</figure>
				<p class="is-box-title mt-2">Documentación</p>
				<p class="is-box-text mt-3">Análizamos, documentamos y nos encargamos de mantener o migrar proyectos legados para evitar perder información o funcionalidad importante.</p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-6.png" />
				</figure>
				<p class="is-box-title mt-2">APIs</p>
				<p class="is-box-text mt-3">Desarrollamos API's para exponer tus funcionalidades ya sea interna o publicamente, desde una interfaz segura y ordenada.</p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-7.png" />
				</figure>
				<p class="is-box-title mt-2">Infraestructura</p>
				<p class="is-box-text mt-3">Diseñamos soluciones enfocadas a la nube y nos encargamos de mantener o actualizar infrastructura utilizando IaaC y herramientas modernas</p>
			</div>
			<div class="box">
				<figure class="image is-inline-block">
					<img src="@/assets/img/services-8.png" />
				</figure>
				<p class="is-box-title mt-2">Aplicaciones EndtoEnd</p>
				<p class="is-box-text mt-3">Creamos software empresarial, partiendo desde el modelado de base de datos hasta las interfaces, microservicios, eventos, websockets, etc.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ServicesMain',
};
</script>

<style scoped>
.section {
	background: var(--Dark-gradient, linear-gradient(9deg, #2c2c31 0%, #18181a 86.48%));
	padding-bottom: 150px;
}

.is-title-section {
	margin-top: 35px;
	margin-bottom: 55px;
}

.is-subtitle {
	color: var(--Blue, #2489ca);
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 5.4px;
}

.is-title {
	font-size: 26px;
	font-weight: 600;
	line-height: normal;
}

.box {
	margin: 20px;
	padding: 20px 30px;
	width: 235px;
	height: 290px;
	border-radius: 8px;
	background: #121214;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
}

.box:hover {
	border: 1px solid var(--Light-Blue, #15d9fd);
}

.image {
	width: 60px;
	margin: 20px 0px;
}

.is-box-title {
	color: var(--Light-Gray, #edeeed);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.is-box-text {
	color: var(--Light-Gray, #edeeed);
	text-align: justify;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 180%; /* 18px */
}

@media only screen and (max-width: 768px) {
	.section {
		padding: 3rem 0px;
		padding-bottom: 150px;
	}

	.is-title-section {
		margin: 40px 20px;
	}

	.is-subtitle {
		font-size: 12px;
		letter-spacing: 3.6px;
	}

	.is-title {
		font-size: 18px;
		line-height: normal;
	}

	.box {
		margin: 8px;
		padding: 15px 25px;
		width: 163px;
		height: 246px;
		border-radius: 6px;
	}

	.image {
		width: 43px;
		margin: 14px 0px;
	}

	.is-box-title {
		font-size: 12px;
	}

	.is-box-text {
		font-size: 9px;
		line-height: normal;
	}
}
</style>
