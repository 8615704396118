<template>
  <div class="slider mt-4">
    <div class="slide-track">
      <div class="slide">
        <img src="@/assets/img/tecnologias/python.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/django.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/aws.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/docker.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/javascript.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/react.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/java.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/python.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/django.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/aws.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/docker.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/javascript.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/react.png" alt="" />
      </div>
      <div class="slide">
        <img src="@/assets/img/tecnologias/java.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
};
</script>

<style scoped>
img {
  height: 80px;
  width: 200px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: #2c2c31;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 100px;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    #2c2c31 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 100px;
  width: 250px;
}

@media only screen and (max-width: 1000px) {
  .slider {
    margin-top: 40px;
  }

  .slider::before,
  .slider::after {
    background: none;
  }

  img {
    height: 60px;
    width: 130px;
  }

  .slider .slide {
    height: 60px;
    width: 150px;
  }
}
</style>