<template>
	<div class="floating-button is-clickable is-flex is-align-items-center is-justify-content-center">
		<span class="icon has-text-blue">
			<i class="fas fa-gamepad fa-2x"></i>
		</span>
	</div>
    <div v-show="showMessage" class="container">
		<div class="arrow">
			<div class="outer"></div>
			<div class="inner"></div>
		</div>
		<div class="message-body">
			<p>Vence a nuestra IA.</p>
		</div>
    </div>
</template>

<script>
export default {
	name: 'GameButton',
    data() {
        return {
            showMessage: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.showMessage = true
        }, 5000)
    }
};
</script>

<style scoped>
.floating-button {
	background-color: #edeeed;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
	position: fixed;
	margin: 15px;
	height: 80px;
	width: 80px;
	border-radius: 80px;
	bottom: 0;
	left: 0;
	z-index: 3;
}

.container {
  clear: both;
  position: fixed;
  bottom: 35px;
  left: 100px;
  z-index: 3;
}
.container .arrow {
  width: 12px;
  height: 20px;
  overflow: hidden;
  position: relative;
  float: left;
  top: 6px;
  right: -1px;
}
.container .arrow .outer {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.container .arrow .inner {
  width: 0;
  height: 0;
  border-right: 20px solid #166b93;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 2px;
}
.container .message-body {
  float: left;
  color: #edeeed;
  background-color: #166b93;
  border: 1px transparent;
  padding: 6px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
</style>
