<template>
  <div class="is-main section has-text-centered">
    <p class="has-text-blue is-subtitle">{{subtitle}}</p>
    <h1 class="is-title mt-3">{{title.split(" ")[0]}} <span class="has-text-blue">{{title.split(" ")[1]}}</span></h1>
    <p class="is-text mt-4">{{text}}</p>
  </div>
</template>

<script>
export default {
 props: ["subtitle", "title", "text"],
 name: "TitleBox"
}
</script>

<style scoped>
.is-main{
  background: var(--Gradient-bottom, linear-gradient(187deg, #2C2C31 40%, #166B93 100%));
  height: 300px;
}

.is-subtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 5.4px;
  text-transform: uppercase;
}

.is-title {
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 60px */
}

.is-text {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}
@media only screen and (max-width: 768px) {
  .is-main {
    height: 180px;
  }
  
  .is-subtitle {
    font-size: 11.68px;
    letter-spacing: 3.504px;
  }

  .is-title {
    font-size: 26px;
  }

  .is-text {
    font-size: 12px;
  }
}
</style>