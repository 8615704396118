<template>
	<div class="section has-black-gradient">
		<div class="columns is-vcentered">
			<div class="column has-text-right-desktop">
				<figure class="image is-inline-block">
					<img src="@/assets/img/chelita-3d.png" />
				</figure>
			</div>
			<div class="column">
                <div class="text-container">
                    <p class="has-text-blue">¿Quiénes somos?</p>
                    <p class="is-subtitle mt-3">Sómos una empresa de desarrollo de software y productos digitales.</p>
                    <p class="is-text mt-3">Desarrollamos aplicaciones móviles, tiendas en línea y software especializado para la administración de negocios.</p>
                    <div class="line-divider"></div>
                    <p class="has-text-light-blue">Nuestra misión</p>
                    <p class="is-text mt-3">Buscamos convertirnos en un referente de innovación y tecnología en México
                        a través de la voz y el desarrollo digital de nuestros clientes.
                    </p>
                     <p class="is-text mt-3">
                        Nos comprometemos con cada proyecto para entregar calidad, funcionalidad e innovación, no solo somos
                        expertos en tecnología y herramientas de vanguardia, también sabemos como brindar un excelente servicio al cliente.
                    </p>
                </div>
            </div>
		</div>
	</div>
    <div class="section has-padding-top-0">
        <div class="container">
            <div class="box-with-blue-gradient is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center">
                <div class="box-text">
                    <p class="is-box-title">50+</p>
                    <p class="is-text">Tecnologías en las que somos expertos</p>
                </div>
                <div class="box-text">
                    <p class="is-box-title">20+</p>
                    <p class="is-text">Proyectos en producción</p>
                </div>
                <div class="box-text">
                    <p class="is-box-title">98%</p>
                    <p class="is-text">Satisfacción del cliente</p>
                </div>
                <div class="box-text">
                    <p class="is-box-title">100000+</p>
                    <p class="is-text">Líneas de código escritas</p>
                </div>
            </div>
        </div>
        <div class="container has-text-centered is-text-section">
            <p class="has-text-blue">Nuestras herramientas</p>
            <p class="is-subtitle mt-3">Tecnologías competitivas en la industria</p>
            <p class="is-text mt-5">Nos valemos de herraminetas probadas y utilizadas en la industria para construir las mejores soluciones</p>
            <div class="slider mt-5 is-inline-block">
                <slider class=""/>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "@/components/Utils/Slider.vue";

export default {
	name: 'AboutUsDescription',
    components: {Slider}
};
</script>

<style scoped>
.section {
    padding-top: 150px;
    height: 700px;
    background: var(--Dark-Gray, #2C2C31);
}

.has-black-gradient {
    background: var(--Dark-gradient, linear-gradient(9deg, #2C2C31 0%, #18181A 86.48%));
}

figure {
    width: 65%;
}

.text-container {
    width: 60%;
}

.has-text-blue {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 5.4px;
    text-transform: uppercase;
}

.is-subtitle {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.is-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.line-divider {
    margin: 35px 0px;
    border-top-style: solid;
    border-top-color: #2489CA;
    border-top-width: 1px;
}

.has-text-light-blue {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.has-padding-top-0 {
    padding-top: 0;
}

.box-with-blue-gradient {
    height: 170px;
    border-radius: 8px;
    background: linear-gradient(90deg, #166B93 0%, #2489CA 48.33%, #15D9FD 99.79%);
}

.box-text {
    margin: 0px 50px;
    text-align: center;
}

.is-box-title {
    font-size: 36px;
    font-weight: 800;
}

.is-text-section {
    margin-top: 100px;
}

.slider {
    width: 95%;
}

@media only screen and (max-width: 768px) {
    .section {
        padding-top: 50px;
        height: unset;
    }

    .text-container {
        width: fit-content;
    }

    figure {
        width: 100%;
    }

    .is-subtitle {
        font-size: 20px;
        line-height: normal;
    }

    .box-with-blue-gradient {
        margin: 0 15%;
        height: 471px;
        justify-content: center!important;;
    }

    .is-box-title {
        font-size: 28px;
        font-weight: 800;
    }
}
</style>
