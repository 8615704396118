<template>
	<div>
		<navigation />
		<main-banner :is-mobile="isMobile"/>
		<main-cards />
		<main-about-us id="nosotros" />
		<div class="is-background-chelita">
			<main-services id="servicios" />
			<Footer :is-mobile="isMobile" id="form"/>
		</div>
		<!--
        <div>
            <div id="pentagon">
            </div>
        </div>
        -->
        <GameButton v-if="false"/>
	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import MainBanner from '@/components/Main/MainBanner.vue';
import MainCards from '@/components/Main/MainCards.vue';
import MainAboutUs from '@/components/Main/MainAboutUs.vue';
import MainServices from '@/components/Main/MainServices.vue';
import Footer from '@/components/Footer.vue';
import GameButton from '@/components/MiniApps/Game/Button.vue'

export default {
	name: 'HomePage',
	components: { Navigation, MainBanner, MainCards, MainAboutUs, MainServices, Footer, GameButton },
	data() {
		return {
			windowWidth: null,
		};
	},
	computed: {
		isMobile() {
			return this.windowWidth <= 769;
		},
	},
	mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
};
</script>

<style>
/*
    #pentagon {
        position: relative;
        bottom: 600px;
        left: 50px;
        width: 1500px;
        height: 1500px;
        transform: rotate(-55deg) skew(15deg, 15deg);;
        background: var(--dark-gray, #2C2C31);        
        border-radius: 0% 25% 0% 0%;
        z-index: 10;
    }
*/
</style>
