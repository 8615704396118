<template>
  <div class="section has-text-centered">
    <p class="has-text-blue">¿QUÉ ESPERAS?</p>
    <p class="is-subtitle">Te invitamos a conocer nuestros servicios</p>
    <div
      class="is-main-cards is-flex is-flex-wrap-wrap is-justify-content-center mt-6"
    >
      <div class="box has-text-left">
        <figure class="image is-inline-block">
            <img src="@/assets/img/statistics.png" />
        </figure>
        <p class="is-subtitle-box mb-3">Diseño UX/UI</p>
        <p class="is-text-box">Nos esforzamos por construir herramientas verdaderamente útiles y fáciles de usar.</p>
      </div>
      <div class="box has-text-left">
        <figure class="image is-inline-block">
            <img src="@/assets/img/security.png" />
        </figure>
        <p class="is-subtitle-box mb-3">Seguridad</p>
        <p class="is-text-box">Implementamos estrategias para mantener tus datos, tus aplicaciones y tu infraesctructura segura.</p>
      </div>
      <div class="box has-text-left">
        <figure class="image is-inline-block">
            <img src="@/assets/img/wallet.png" />
        </figure>
        <p class="is-subtitle-box mb-3">Transacciones</p>
        <p class="is-text-box">Desarrollamos integraciones y automatización para servicios pagos, envíos, comunicaciones, firmas, etc.</p>
      </div>
      <div class="box has-text-left">
        <figure class="image is-inline-block">
            <img src="@/assets/img/cloud.png" />
        </figure>
        <p class="is-subtitle-box mb-3">Computo en la nube</p>
        <p class="is-text-box">Nuestras soluciones se basan en tecnologías de la nube de última generación: serverless, contenedores y almacenamiento dinámico</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.has-text-blue {
  font-size: 18px;
  letter-spacing: 5.4px;
  font-weight: 600;
}

.is-subtitle {
  font-size: 28px;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
    .has-text-blue {
        font-size: 12px;
    }

    .is-subtitle {
        margin-top: 10px;
        font-size: 18px;
        line-height: normal;
    }
}

.box {
    margin: 10px;
    padding: 30px;
    border-radius: 8px;
    width: 235px;
    height: 290px;
    background: #2C2C31;
}

.is-subtitle-box {
    font-size: 16px;
    font-weight: 600;
    color: var(--light-gray, #EDEEED);
}

.is-text-box {
    font-size: 10px;
    font-weight: 600;
    color: var(--light-gray, #EDEEED);
    line-height: 180%; /* 18px */
}
</style>