<template>
  <div>
    <div class="section">
		<div class="has-text-centered is-title-section">
			<p class="is-subtitle">RAZONES PARA ELEGIRNOS</p>
			<p class="is-title mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
		</div>
        <div class="container is-flex is-justify-content-center">
            <div class="tag is-light m-3">Todo</div>
            <div class="tag is-light m-3">Diseño UI/UX</div>
            <div class="tag is-light m-3">Desarrollo</div>
            <div class="tag is-light m-3">Ecommerce</div>
            <div class="tag is-light m-3">Lorem</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProjectsMain"
}
</script>

<style>
.is-title-section {
	margin-top: 35px;
	margin-bottom: 55px;
}

.is-subtitle {
	color: var(--Blue, #2489ca);
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 5.4px;
}

.is-title {
	font-size: 26px;
	font-weight: 600;
	line-height: normal;
}
</style>