<template>
	<div>
		<navigation />
		<title-box :subtitle="subtitle" :title="title" :text="text" />
		<services-main />
		<div class="is-background-chelita">
			<services-banner />
			<Footer :is-mobile="isMobile" id="form" />
		</div>
	</div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import TitleBox from '@/components/Utils/TitleBox.vue';
import ServicesMain from '@/components/Services/ServicesMain.vue';
import ServicesBanner from '@/components/Services/ServicesBanner.vue';

export default {
	name: 'Services',
	components: { Navigation, Footer, TitleBox, ServicesMain, ServicesBanner },
	data() {
		return {
			subtitle: 'Permitenos guíarte',
			title: 'Nuestros Servicios',
			text: 'Construimos tecnología e innovación',
		};
	},
    computed: {
		isMobile() {
			return this.windowWidth <= 769;
		}
	},
    mounted() {
		this.windowWidth = window.innerWidth;
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
	},
};
</script>

<style></style>
