<template>
    <div class="section">
        <div class="container has-text-centered">
            <p class="is-subtitle">A TU SERVICIO</p>
            <p class="is-title">Nuestro equipo de expertos</p>
        </div>
        <div class="container is-flex is-flex-wrap-wrap is-justify-content-center">
            <div class="is-team-box is-teamate-1 is-flex is-align-items-flex-end" @mouseover="hoverFirst = true" @mouseleave="hoverFirst = false">
                <div class="is-team-box-content">
                    <p class="is-teamate-name">John</p>
                    <p class="is-teamate-name">Doe Jackson</p>
                    <p class="is-teamate-title">CEO</p>
                    <p v-if="hoverFirst" class="is-teamate-description mt-2">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="is-team-box is-teamate-2 is-flex is-align-items-flex-end" @mouseover="hoverSecond = true" @mouseleave="hoverSecond = false">
                <div class="is-team-box-content">
                    <p class="is-teamate-name">John</p>
                    <p class="is-teamate-name">Doe Jackson</p>
                    <p class="is-teamate-title">Software Engineer</p>
                    <p v-if="hoverSecond" class="is-teamate-description mt-2">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="is-team-box is-teamate-3 is-flex is-align-items-flex-end" @mouseover="hoverThird = true" @mouseleave="hoverThird = false">
                <div class="is-team-box-content">
                    <p class="is-teamate-name">John</p>
                    <p class="is-teamate-name">Doe Jackson</p>
                    <p class="is-teamate-title">Sr Designer</p>
                    <p v-if="hoverThird" class="is-teamate-description mt-2">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUsTeam",
    data() {
        return {
            hoverFirst: false,
            hoverSecond: false,
            hoverThird: false
        }
    }
}
</script>

<style scoped>
.section .container {
    margin-bottom: 50px;
}

.is-subtitle {
    color: var(--Blue, #2489CA);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 5.4px;
}

.is-title {
    font-size: 28px;
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
    .is-subtitle {
        font-size: 12px;
        letter-spacing: 3.6px;
    }

    .is-title {
        font-size: 24px;
    }
}

.is-team-box {
    height: 330px;
    width: 250px;
    background-size: cover;
    cursor:pointer;
}

.is-teamate-1 {
    background-image: url('@/assets/img/teamate-1.png');
}

.is-teamate-2 {
    background-image: url('@/assets/img/teamate-2.png');
}

.is-teamate-3 {
    background-image: url('@/assets/img/teamate-3.png');
}

.is-team-box-content {
    margin: 20px;
    padding: 5px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #15D9FD;
}

.is-teamate-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.is-teamate-title {
    color: var(--Light-Blue, #15D9FD);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}

.is-teamate-description {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
</style>