<template>
  <div class="section">
    <div class="columns is-vcentered">
      <div
        class="column is-half has-text-right-desktop has-text-centered-mobile p-2"
      >
        <figure class="image is-inline-block mb-mobile">
          <img src="@/assets/img/main-about-us.png" />
        </figure>
      </div>
      <div class="column is-3">
        <p class="has-text-blue mb-3">¿QUIÉNES SOMOS?</p>
        <p class="is-subtitle mb-4">
          Algo más que una empresa de desarrollo de software.
        </p>
        <p class="is-text mb-6">
          Nuestra misión es ayudarte a construir herramientas que faciliten la operación de tu negocio, 
          diseñamos según tu necesidad utilizando la mejor tecnología y soluciones de 
          software, aplicaciones y computo en la nube
        </p>
        <p class="is-text is-max-content-width mb-2">
            <span class="mr-3"><i class="fas fa-check"></i></span> 
            Automatizamos el trabajo repetitivo
        </p>
        <p class="is-text mb-2">
            <span class="mr-3"><i class="fas fa-check"></i></span> 
            Construimos herramientas amigables
        </p>
        <p class="is-text is-max-content-width mb-2">
            <span class="mr-3"><i class="fas fa-check"></i></span> 
            Utilizamos tecnología de vanguardia
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainAboutUs",
};
</script>

<style scoped>
.section {
    padding-bottom: 130px;
}

figure {
    width: 60%;
}

.has-text-blue {
  letter-spacing: 5.4px;
  font-weight: 600;
}

.is-subtitle {
  font-size: 28px;
  line-height: normal;
}

.is-text-section {
  width: 100px;
}

.is-text {
  font-size: 16px;
  font-weight: 600;
}

.is-max-content-width {
    width: max-content;
}

@media only screen and (max-width: 768px) {
    figure {
        width: 80%;
    }

    .has-text-blue {
        font-size: 12px;
        letter-spacing: 3.6px;
    }

    .is-subtitle {
        font-size: 18px;
    }

    .is-text {
        font-size: 12px;
    }

    .mb-mobile {
        margin-bottom: 50px;
    }

    .is-max-content-width {
        width: 100%;
    }
}

span {
    font-size: 10px;
    padding: 5px 6px;
    border-radius: 50px;
    background: #DCF1FF;
    color: #2489CA;
}
</style>