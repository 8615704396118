<template>
	<div class="section">
		<div class="container">
			<div class="box-with-blue-gradient is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center">
				<figure class="image is-inline-block">
					<img src="@/assets/img/chelita-call.png" />
				</figure>
				<div class="is-contact-number-section">
					<p class="is-text">Tomemos una chelita:</p>
					<p class="is-phone">55 2940 5706</p>
				</div>
				<div class="divider"></div>
				<p class="is-subtitle">Hablemos de transformación digital</p>
				<a
					href="https://api.whatsapp.com/send?phone=+525529405706&text=Hola, necesito más información sobre Chelita Software!"
					class="button"
				>
					<i class="fab fa-whatsapp mr-2"></i> Contáctanos
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ServicesBanner',
};
</script>

<style scoped>
.section {
	padding: 0px;
	margin: 0px;
}

.box-with-blue-gradient {
	height: 170px;
	border-radius: 8px;
	background: linear-gradient(90deg, #166b93 0%, #2489ca 48.33%, #15d9fd 99.79%);
	position: relative;
	top: -80px;
}

.image {
	width: 35px;
}

.is-subtitle {
	color: white;
	border-left: solid 1px #edeeed;
	padding: 0px 26px;
	width: 310px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.is-contact-number-section {
	text-align: center;
	margin-left: 20px;
	margin-right: 26px;
}

.is-text {
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 180%;
}

.is-phone {
	color: var(--Light-Blue, #15d9fd);
	font-size: 21px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
}

.button {
	color: var(--Dark-Blue, #166b93);
	width: 155px;
	height: 42px;
	padding: 10px 15px;
	font-size: 15px;
	font-weight: 600;
	border-radius: 50px;
	background: var(--Light-Gray, #edeeed);
}

@media only screen and (max-width: 768px) {
	.box-with-blue-gradient {
		margin: 0 20%;
		padding-bottom: 20px;
		height: 350px;
		background: linear-gradient(180deg, #166b93 0%, #2489ca 48.33%, #15d9fd 99.79%);
	}

	.image {
		width: 60px;
		margin: 15px;
	}

	.is-subtitle {
		text-align: center;
		border-left: none;
		font-size: 12px;
	}

	.divider {
		border-top: solid 1px #edeeed;
		width: 100px;
	}
}
</style>
